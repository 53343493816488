<template>
  <div id="activities">
    <Welcome
      :title="welcome.title"
      :subtitle="welcome.subtitle"
      :backgroundStyle="welcome.backgroundStyle"
      :show="false"
    />
    <div class="ActivityItem">
      <ActivitiesItemDetails
        class="Items"
        v-for="activityItem in activityItems"
        :key="activityItem.id"
        :activityTitle="activityItem.activityTitle"
        :activitySubtitle="activityItem.activitySubtitle"
      />
    </div>
    <div class="Programs">
      <h1>Our Ongoing Programs</h1>
      <Programs
        class="ProgramItems"
        v-for="program in programs"
        :key="program.id"
        :id="program.id"
        :Title="program.Title"
        :Description="program.Description"
        :Programimage="program.Programimage"
        :showToGetInvolved="true"
      />
    </div>
  </div>
</template>

<script>
import Welcome from "@/components/WelcomeSection.vue";
import ActivitiesItemDetails from "../components/ActivitiesItemDetails";
import Programs from "../components/Programs";
export default {
  components: {
    Welcome,
    ActivitiesItemDetails,
    Programs,
  },
  data() {
    return {
      welcome: {
        title: "Get Involved",
        subtitle: "Creating A Better Tomorrow...",
        backgroundStyle: {
          backgroundImage: `url(${require("../assets/Activities/Activities-bg-image.png")})`,
        },
      },
      activityItems: [
        {
          activityTitle: "Become A Long Term Volunteer",
          activitySubtitle:
            "Long-term volunteers can volunteer to take part in a program for a period of up to 1 year",
        },
        {
          activityTitle: "Adapt A Project",
          activitySubtitle:
            "You can chose to select a project and adapt it to your liking. There are projects that can benefit from an update.",
        },
        {
          activityTitle: "Become A Partner",
          activitySubtitle:
            "You can do more by becoming a partner. There are programs that you can be involved in by becoming a partner, where you work with us side by side to set up a project and execute it.",
        },
        {
          activityTitle: "Donate",
          activitySubtitle:
            "You can donate to a given project that you feel can impact your area of interest. There are different wayf of donating to the different projects that we have.",
        },
      ],
      programs: [
        {
          id: 1,
          Title: "Tree Planting, Growing and Teaching",
          Description:
            "GLP plants trees in schools, orphanages, villages, and other open areas. When GLP begins a partnership with a school, our first aim is to educate students about the different environmental challenges and help them to identify possible solutions.",
          Programimage: require("../assets/Activities/tree-planting.png"),
        },
        {
          id: 2,
          Title: "Sustainable Gardening",
          Description:
            "Each school that GLP supports will participate in a school lunch program where the goal is to start a sustainable source of food and nutrition for the students. In most cases, volunteers will help build African-style keyhole gardens to grow the food necessary for a school lunch program. Volunteers can participate in the preparation of the project, fundraising, and the actual build alongside students.",
          Programimage: require("../assets/Activities/sustainable-gardening.png"),
        },
        {
          id: 3,
          Title: "Access To Clean Water",
          Description:
            "GLP is committed to making a lasting impact in the areas that we work in. We work with local chairmen and other community leaders to identify a viable solution to the lack of access to clean water in the community surrounding the schools we partner with. Each of these projects will be different as each community will have different needs.",
          Programimage: require("../assets/Activities/access-to-clean-water.png"),
        },
        {
          id: 4,
          Title: "Permaculture",
          Description:
            "Permaculture is an integral part of our program as it moves beyond women and children to include working with farmers and homeowners within the community.  Volunteers with a background in permaculture who choose to participate in permaculture training for the community will help to compile manuals and guides and run short courses alongside GLP, teaching the local community and Maasai Villages.",
          Programimage: require("../assets/Activities/permaculture.png"),
        },
        {
          id: 5,
          Title: "Recycling",
          Description:
            "We're conscious of the waste that we create and the impact it has on the environment. We have experimented with planting trees using bio-degradable toilet roll holders instead of plastic bags and we use a filtered sewage system for our center’s vegetable gardens. Currently, we are experimenting with old wine bottles to make new drinking glasses, candle holders, and mirrors.",
          Programimage: require("../assets/Activities/recycling.png"),
        },
        {
          id: 6,
          Title: "Women’s Development Projects",
          Description:
            "GLP encourages the creation of a women’s development group in each of the schools we work with. Most often, these groups exist as a vehicle for income generation for women to have the opportunity to make extra money for their families. ",
          Programimage: require("../assets/Activities/women-development.png"),
        },
        {
          id: 7,
          Title: "Medical Programmes",
          Description:
            "Our volunteers work in medical clinics, dispensaries, and hospitals in and around Arusha. Volunteer tasks vary and depend on the level of experience, qualifications, etc. Medical students can assist the local doctors and staff with basic tasks such as checking the weight and temperature of the students, cleaning wounds, and other administrative tasks. They work in a variety of areas including maternal health, surgery, laboratories, counseling, and testing, etc.",
          Programimage: require("../assets/Activities/medical-programs.png"),
        },
        {
          id: 8,
          Title: "Working At An Orphanage",
          Description:
            "We work with different orphanages in and around Arusha. As a volunteer, your main role would be to work alongside the existing staff to care for the children in orphanages. The work in a children’s home involves teaching children and educational games, helping at mealtimes, and supporting daily duties such as laundry and cleaning. Volunteers will also help the children with homework and play games.",
          Programimage: require("../assets/Activities/working-at-orphanage.png"),
        },
      ],
    };
  },
};
</script>

<style scoped>
.ActivityItem {
  margin: auto;
  max-width: 1400px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.Items {
  flex: 0 440px;
  margin: 30px;
}
.ProgramItems {
  margin: 150px 0px 150px 0px;
}
.Programs {
  color: #006233;
  /* font-family: "Gill Sans"; */
  font-size: 64px;
  font-weight: 600;
  line-height: 76px;
  text-align: center;
}
.Programs h1 {
  color: #006233;
  /* font-family: "Gill Sans"; */
  font-size: 40px;
  font-weight: 600;
  line-height: 76px;
  text-align: center;
}
@media screen and (max-width: 720px) {
  .Programs h1 {
    color: #006233;
    /* font-family: "Gill Sans"; */
    font-size: 20px;
  }
}
</style>