<template>
  <div class="ActivityItem">
    <h1>{{ activityTitle }}</h1>
    <p>
      {{ activitySubtitle }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    activityTitle: String,
    activitySubtitle: String,
  },
};
</script>

<style scoped>
.ActivityItem {
  display: grid;
  text-align: left;
  padding: 10px;
  /* height: 184px; */
  max-width: 440px;
  background-color: #006233;
  color: #fff;
}
h1 {
  width: 100%;
  text-align: left;
  /* font-family: "Gill Sans"; */
  font-size: 30px;
  line-height: 46px;
}
p {
  width: 100%;
  color: #fff;
  font-size: 16px;
  line-height: 23px;
}
</style>